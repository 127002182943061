@supports not (font-variation-settings: normal) {
	@font-face {
		font-family: Nunito;
		src: url("./fonts/Nunito-Regular.53a5ca69.ttf") format("truetype");
		font-weight: 400;
		font-display: swap;
		font-style: normal;
	}

	@font-face {
		font-family: Nunito;
		src: url("./fonts/Nunito-Medium.bed7d7c4.ttf") format("truetype");
		font-weight: 500;
		font-display: swap;
		font-style: normal;
	}

	@font-face {
		font-family: Nunito;
		src: url("./fonts/Nunito-SemiBold.6c5467fd.ttf") format("truetype");
		font-weight: 600;
		font-display: swap;
		font-style: normal;
	}

	@font-face {
		font-family: Nunito;
		src: url("./fonts/Nunito-Bold.1bf75fbc.ttf") format("truetype");
		font-weight: 700;
		font-display: swap;
		font-style: normal;
	}

	@font-face {
		font-family: Nunito;
		src: url("./fonts/Nunito-ExtraBold.52758667.ttf") format("truetype");
		font-weight: 800;
		font-display: swap;
		font-style: normal;
	}
}

@supports (font-variation-settings: normal) {
	@font-face {
		font-family: Nunito;
		src: url("./fonts/Nunito-VariableFont_wght.3bb00c12.ttf")
		format("truetype");
		font-weight: 100 1000;
		font-stretch: 25% 151%;
		font-display: swap;
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

html, body, #root {
	height: 100%;
}

body {
	margin: 0;
	font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #3a3a3a;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.nav, .nav:hover{
	text-decoration: none;
	color: #333;
	font-size: 17px;
	padding: 0 4px;
	margin: 0 12px;
}

button.nav{
	border: 0 none;
	background-color: transparent;
	cursor: pointer;
}

input.form-control{
    padding: 14px 14px;
	border-radius: 14px;
}

input.form-control:hover{
	border-color: #000000de;
}

input.form-control:focus{
	border-color: #ff5a6a;
	border-width: 2px;
	padding-top: 13px;
	padding-bottom: 13px;
	outline: 0 none;
	box-shadow: none;
}

button.dropdown-toggle{
    padding: 14px 14px;
	border-radius: 14px;
}

button.dropdown-toggle::after{
	top: 24px !important;
}

.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
	background-color: #ff5a6a !important;
}

.swiper{
	padding-left: 10px !important;
	padding-right: 10px !important;
}

#autocomplete-wrapper{
	display: flex;
	flex: 1;
	align-items: center;
	background-color: #fff;
    padding: 6px 0;
	position: relative;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 14px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#autocomplete-wrapper:hover{
	border-color: #000000de;
}

#autocomplete-wrapper:focus-within{
	border-color: #ff5a6a;
	border-width: 2px;
	padding-top: 5px;
	padding-bottom: 5px;
}

#autocomplete-wrapper .rbt-aux{
	position: absolute;
    top: 9px;
    right: 14px;
    opacity: .3;
}

.autocomplete-address-row{
	display: flex;
	flex: 1;
}

.autocomplete-address-row.hide{
	display: none;
}

.utility-select{
	margin-left: 12px;
}

.utility-select .dropdown-toggle{
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 14px;
    padding: 15px 12px;
}

.utility-select .dropdown-toggle:hover{
	border-color: #000000de;
}

.utility-select .dropdown-toggle:focus{
	border-color: #ff5a6a;
	border-width: 2px;
	padding-top: 14px;
	padding-bottom: 14px;
}

#autocomplete-wrapper input{
	background-color: transparent;
	border: 0 none;
	padding: 0 16px;
	font-size: 16px;
}

#autocomplete-wrapper input:focus{
	box-shadow: none;
}

#autocomplete-wrapper>div{
	flex: 1;
}

#autocomplete-wrapper .dropdown-menu{
	top: 8px !important;
	width: 100% !important;
}

#autocomplete-wrapper .dropdown-item{
	padding: 8px 16px;
}

#autocomplete-wrapper .dropdown-item mark{
	background-color: #ffdee14d;
	border-radius: 4px;
	padding: 0 4px;
}

.plan-saving{
	display: inline-block;
	margin: 6px auto 8px;
	font-size: 13px;
	font-weight: 500;
	border-bottom: 1.5px dashed #666;
	color: #666;
}

.plan-saving a{
	text-decoration: none;
	color: inherit;
}

.plan-type-options{
	position: relative;
	bottom: -1px;
}

.plan-type-options .nav-tabs{
	border-bottom: 0 none;
}

.form-control.is-valid, .was-validated .form-control:valid{
    border-color: #ced4da;
    background-image: none;
}

#agreement-wrapper{
	border: 1px solid #ddd;
	padding: 4px 24px;
    max-height: 700px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

#agreement-wrapper table,#agreement-wrapper td{
	position: static;
}

.form-group{
	margin: 20px 0;
}

.page-wrapper{
	position: relative;
}

.page-wrapper>div{
	position: absolute;
	width: 100%;
}

#plans-wrapper{
	display: flex;
	padding-top: 20px;
}

#plans-list{
	padding: 24px 32px;
	flex: 1;
	overflow: hidden;
}

.plan-compare-btn, .plan-compare-btn:hover{
	background-color: #fff;
	border: 1px solid #ddd;
}

.plan-compare-btn.active{
	background-color: #ff5a6a;
	color: #fff;
	border: 1px solid #ff5a6a;
}

.plans-filter-options{
	display: flex;
	flex-wrap: wrap;
}

.plan-row-wrapper{
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 12px;
	margin: 20px 0;
	box-shadow: 0 3px 8px rgba(0, 0, 0, .03);
	overflow: hidden;
	transition: border-color .5s;
}

.plan-row-wrapper:hover{
	border-color: #999;
}

.plan-row{
	display: flex;
	padding: 0 16px;
}

.checkbox-item{
	display: flex;
	align-items: center;
	padding: 4px 12px;
	cursor: pointer;
	margin: 8px 0;
	flex: 0 0 50%;
	overflow: hidden;
}

.checkbox-item span{
	margin-left: 8px;
	color: #666;
	font-size: 15px;
	line-height: 1.1;
}

.checkbox-item span a{
	color: #666;
}

.checkbox-item.onlyread{
	opacity: .4;
	cursor: default;
}

.checkbox-item.full{
	flex: 0 0 100%;
}

.plans-filter-title{
	font-weight: 500;
	font-size: 17px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.rep-logo{
	width: 80px;
	height: 42px;
}

.rep-logo i{
	display: inline-block;
	font-size: 11px;
	color: #999;
	font-style: normal;
	line-height: 1;
}

.rep-logo img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.plan-overview{
	display: flex;
	align-items: center;
}

.plan-info-group{
	display: flex;
	align-items: center;
	flex: 1;
}

.plan-info{
	flex: 1;
	text-align: center;
}

.plan-info>div{
	font-size: 18px;
	font-weight: 500;
	margin-bottom: -4px;
}

.plan-info label{
	font-size: 14px;
	color: #666;
	line-height: 1.2;
}

.plan-content{
	flex: 1;
	padding: 20px 0;
}

.plan-options{
	width: 200px;
	flex-shrink: 0;
	border-left: 1px solid #ddd;
	margin-right: -16px;
	margin-left: 16px;
    background: #F9FFFC;
	padding: 20px 0;
}

.plan-name{
	font-size: 15px;
	font-weight: 500;
	line-height: 1.3;
}

.plan-info .plan-est-rate{
	color: #ff5a6a;
	font-size: 22px;
}

.plan-links{
	display: flex;
}

.plan-links a{
	display: block;
	margin-right: 24px;
	color: #666;
}

.plan-detail{
	max-height: 0;
	overflow: hidden;
	padding: 0 20px;
	font-size: 15px;
	background-color: #fbfbfb;
}

.plan-detail.active{
	max-height: 9999px;
}

.mask{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .4);
}

.checkbox-row .checkbox-item{
	align-items: flex-start;
}

.checkbox-row .checkbox-item span{
	position: relative;
	top: -2px;
    margin-left: 12px;
}

.checkbox-row .checkbox-item span.error{
	color: #dc3545;
}

.nav-link, .nav-link:focus, .nav-link:hover{
	color: #666;
}

.nav-tabs .nav-link.active{
	border-top: 2px solid #ff5a6a;
}

.plans-filter-dd button{
	text-align: left;
	position: relative;
	padding-right: 32px;
}

.plans-filter-dd button::after{
	position: absolute;
	top: 16px;
	right: 10px;
}

.plans-filter-dd .dropdown-menu{
	width: 100%;
	max-height: 300px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.plans-filter-dd .dropdown-item{
	padding: 8px 16px;
	white-space: break-spaces;
}

.dropdown-item.active, .dropdown-item:active{
	background: #ff5a6a;
}

.page-link, .page-link:hover{
	color: #666;
}

.page-item.active .page-link{
	color: #fff;
	background-color: #30D886;
	border-color: #30D886;
}

.modal-header button.close{
	background-color: transparent;
	border: 0 none;
	font-size: 26px;
	font-weight: 500;
	color: #999;
	z-index: 2;
}

.plan-tags span{
	font-size: 11px;
	font-weight: 500;
	color: #777;
	background-color: #f9f9f9;
	border-radius: 3px;
	display: inline-block;
	padding: 2px 6px;
	margin-right: 8px;
	margin-bottom: 4px;
    text-transform: uppercase;
}

.plan-detail-heading{
	font-size: 17px;
	margin-bottom: 8px;
	font-weight: 700;
}

.breakdown-table-wrapper{
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
	margin-bottom: 24px;
	margin-left: -20px;
	margin-right: -20px;
	padding: 0 20px;
}

.breakdown-table-wrapper table{
	border: 1px solid #ddd;
}

.breakdown-table-wrapper td, .breakdown-table-wrapper th{
    text-align: center;
    padding: 12px 8px;
	font-size: 13px;
}

.breakdown-table-wrapper td.disabled, .breakdown-table-wrapper th.disabled{
    background-color: #f9f9f9;
    color: #ccc;
}

.breakdown-table-wrapper th{
	background-color: #f9f9f9;
}

.breakdown-table-wrapper td{
	background-color: #fff;
}

#plans-filter-toggle, #plans-filter-group .close-btn{
	display: none;
}

#plans-filter-group{
	margin-top: 32px;
}

.plans-filter{
	margin-bottom: 32px;
}

.get-usage-row{
	background-color: #f8f9fa;
	padding: 16px 20px;
	border-radius: 4px;
	border: 1px solid #ddd;
}

.drop-area{
	padding-top: 64px;
	padding-bottom: 64px;
	border: 2px dashed #ddd;
	border-radius: 12px;
	margin-bottom: 24px;
	cursor: pointer;
}

.drop-area.active{
	background-color: #E9F8F5;
	border-color: #30D886;
}

.list-group-item.active{
	background-color: #E9F8F5;
	border-color: #30D886;
	color: #30D886;
}

.list-group-item{
	padding-top: 12px;
	padding-bottom: 12px;
}

#term-filter-wrapper{
	margin-right: 12px;
}

#get-usage-option-wrapper{
	display: flex;
	flex-direction: column;
	margin-top: 12px;
}

.get-usage-option{
	cursor: pointer;
	border: 1px solid #ddd;
	border-radius: 12px;
	flex: 1;
	padding: 16px 20px;
	margin: 8px 0;
	display: flex;
	align-items: center;
	transition: border .3s;
	position: relative;
}

.get-usage-option h5{
	font-size: 17px;
}

.get-usage-option:hover{
	border-color: #999;
}

.get-usage-option p{
	margin-bottom: 0;
	font-size: 15px;
	line-height: 1.2;
}

.get-usage-option i{
	display: inline-block;
	font-style: normal;
	position: absolute;
	top: 3px;
	right: 3px;
	border-radius: 8px;
	padding: 1px 8px;
	font-size: 13px;
	font-weight: 600;
	background: linear-gradient(135deg, #17C2C4 0%, #30D886 100%);
	color: #fff;
}

.get-usage-option.highlight{
	border-color: #30D886;
	padding-top: 44px;
	padding-bottom: 44px;
	background-color: #F9FFFC;
}

.divider{
	text-align: center;
	border-bottom: 1px dotted #ccc;
	margin: 24px auto 36px;
	max-width: 540px;
}

.divider span{
	position: relative;
	font-size: 14px;
	top: 12px;
	color: #999;
	background-color: #fff;
	padding: 0 24px;
}

.container-md{
	max-width: 640px;
	margin: 44px auto 64px;
}

/* custom model */
.modal-header{
	border-bottom: 0 none;
	text-align: center;
	display: block;
}

.modal-title{
	margin-top: 32px;
	font-weight: 700;
}

.modal-header button.close{
	position: absolute;
	top: 16px;
	right: 20px;
}

.modal-content{
	border-radius: 12px;
    border: 0 none;
}

.modal-body{
	padding-left: 28px;
	padding-right: 28px;
}

/* fix react datepicker arrow position */
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
	left: 50% !important;
    transform: translate3d(-50%, 0px, 0px) !important;
}

.react-datepicker__navigation{
	top: 8px !important;
}

.tooltip-mark{
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	display: inline-block;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	border-radius: 50%;
	background-color: #B5DFC3;
	color: #fff;
	cursor: default;
}

.tooltip{
	max-width: 240px;
	font-weight: 500;
}

#watch-video-poster{
	padding: 1px;
}

.plan-options button{
	font-size: 15px;
	width: 80%;
}

.bar-cell .bar, .bar-cell .bar-top, .bar-cell:hover .bar{
	fill: #F3F3F3;
}

.bar-cell.special .bar, .bar-cell.special .bar-top, .bar-cell.special:hover .bar{
	fill: #CEF5F1;
}

.bar-cell:hover .bar-top{
	fill: #5F5F5F;
}

.bar-cell.special:hover .bar-top{
	fill: #CEF5F1;
}

.error .form-control, .error .dropdown button.dropdown-toggle{
	border-color: #d32f2f;
}


.ReactModal__Body--open,
.ReactModal__Html--open{
	overflow: hidden;
}

.ReactModal__Overlay {
    opacity: .1;
    transition: opacity .3s ease-out;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 101;
    backdrop-filter: blur(6px);
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.ReactModal__Content {
    transform: translate(0, -30px);
    transition: transform .2s ease-out;
    border: 0 none !important;
    bottom: auto !important;
    top: 12px !important;
    left: 12px !important;
    right: 12px !important;
    max-width: 580px;
    margin: 0 auto 12px !important;
	padding-top: 44px !important;
    border-radius: 30px !important;
}

@media (max-width: 540px){
	.ReactModal__Content {
		padding-top: 64px !important;
	}
}

.ReactModal__Content--after-open{
    transform: translate(0, 0);
}

.ReactModal__Content--before-close{
    transform: translate(0, -30px);
}

.main-section button.dropdown-toggle {
	background-color: #fff;
	border-color: #ced4da;
	color: #212529;
	padding-top: 12px;
	padding-bottom: 12px;
    width: 206px;
    display: flex;
    justify-content: space-between;
    align-items: center;
	box-sizing: content-box;
}

.main-section button.dropdown-toggle:hover {
	color: inherit;
	border-color: #000000de;
}

.main-section button.dropdown-toggle:focus {
	border-color: #ff5a6a;
	border-width: 2px;
}

.upper1Letter::first-letter {
	text-transform: capitalize;
}

.first-section {
	background: #fff;
	/* background: linear-gradient(180deg, #FFFFFF 43.09%, #FFF2F5 103.32%); */
}

.section_title {
    font-size: 34px;
    font-weight: 700;
	text-align: center;
}

.swiper-horizontal {
	width: 100%;
}

.swiper-button-prev, .swiper-button-next {
	display: none;
}

.swiper {
	padding-bottom: 30px !important;
}

.swiper-pagination {
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: rgba(33, 33, 33, 0.52);
	opacity: 0.3;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: #FF5A6A;
	opacity: 1;
}


@media (max-width: 1170px) {
	.autocomplete-address-row{
		flex-direction: column;
	}

	.utility-select{
		margin-left: 0;
		margin-top: 12px;
		flex: 1;
	}

	.utility-select .dropdown-toggle, .utility-select .dropdown-menu{
		width: 100%;
	}

	.plan-overview{
		flex-direction: column-reverse;
		align-items: flex-start;
	}

	.plan-options{
		width: 180px;
	}

	.plan-info-group{
		width: 100%;
	}

	.plan-info-group .plan-info{
		text-align: left;
		padding-left: 4px;
	}
	
	.plan-intro{
		padding-left: 84px;
		margin-top: -54px;
	}
}

@media (max-width: 750px) {
	#plans-wrapper{
		flex-direction: column;
		padding-top: 0;
	}

	#plans-filter-toggle{
		display: inline-block;
		flex: 1;
	}

	#plans-sort-toggle{
		flex: 1;
	}

	#plans-sort-toggle .dropdown-toggle{
		width: 100%;
	}

	#plans-filter-group{
		display: none;
		margin-top: 0;
	}

	#plans-filter-group.show{
		position: fixed;
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #fff;
		z-index: 10;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		padding: 88px 20px 44px;
	}

	#plans-filter-group.show .close-btn{
		display: block;
	}

	#plans-list-header{
		flex-direction: column;
	}

	#term-filter-wrapper{
		margin-right: 0;
		margin-bottom: 12px;
		width: 100%;
	}

	#term-filter-wrapper .plans-filter-options,
	#term-filter-wrapper .plans-filter-dd,
	#term-filter-wrapper .plans-filter-dd button{
		width: 100%;
	}
	
	#term-filter-wrapper .plans-filter-dd button{
		text-align: center;
	}
}

@media (max-width: 560px) {
	#plans-list{
		padding-left: 12px;
		padding-right: 12px;
	}

	.plan-info-group{
		align-items: flex-start;
	}

	.plan-options{
		width: 130px;
	}

	.plan-options button{
		font-size: 12px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.rep-logo{
		width: 65px;
		height: 33px;
	}

	.rep-logo i{
		margin-top: 4px;
		font-size: 10px;
	}

	.plan-info>div{
		font-size: 16px;
	}

	.plan-info label{
		font-size: 13px;
		line-height: 1.1;
		margin-top: 6px;
	}

	.plan-options .plan-info label{
		padding: 0 12px;
	}

	.plan-info .plan-est-rate{
		font-size: 17px;
	}

	.plan-row-wrapper{
		margin-left: -4px;
		margin-right: -4px;
	}

	.plan-row{
		padding: 0 12px;
	}

	.plan-intro{
		padding-left: 75px;
	}

	.nav-tabs .nav-link{
		font-size: 14px;
	}

	.plan-provider-contact{
		font-size: 14px;
		margin-top: 12px;
	}

	.plan-info.plan-info-terms span{
		font-size: 12px;
	}

	.modal-body{
		padding-left: 16px;
		padding-right: 16px;
	}
}